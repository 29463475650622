import http from '@/services/http'

class EmissionRequest {
  fetch(payload) {
    return http.get(`/admin/cedente/serie/solicitacao_emissao`, { params: payload })
  }

  get(id) {
    return http.get(`/admin/cedente/serie/solicitacao_emissao/${id}`)
  }

  confirm(id) {
    return http.post(`/admin/cedente/serie/solicitacao_emissao/${id}/confirmar_emissao`)
  }

  reject(payload) {
    return http.post(`/admin/cedente/serie/solicitacao_emissao/${payload.id}/rejeitar`, payload)
  }
}

export default new EmissionRequest()
